<template>
  <div>
    <v-card>
      <div class="d-flex">
        <div style="flex: 258px 0 0">
          <v-card-title>
            <span class="text-lg">
              {{ $t('WelcomeStaffName') }}:
            </span>
          </v-card-title>
          <v-card-subtitle><span class="font-weight-bold"><span class="font-weight-bold">{{ userdata.full_name }}</span></span></v-card-subtitle>
          <v-card-text>
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="primary"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiTicketPercentOutline }}
                  </v-icon>
                </v-avatar>

                <div class="ms-2">
                  <p class="text-xs mb-0">
                    {{ $t('CouponScannedToday') }}
                  </p>
                  <h3 class="text-xl font-weight-bold">
                    {{ info.total_coupon }}
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  block
                  small
                  :to="{name:'staff-cafe-activity'}"
                >
                  {{ $t('ViewActivity') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-spacer></v-spacer>
        <div class="illustrator-img">
          <v-img
            :width="111"
            :src="require('@/assets/images/avatars/10.png')"
          ></v-img>
        </div>
      </div>
    </v-card>
    <v-card
      class="mt-4"
    >
      <v-card-text>
        <v-btn
          color="primary"
          block
          x-large
          :to="{name:'staff-cafe-scan-coupon'}"
        >
          {{ $t('ScanCoupon') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CafeService from '@/services/CafeService'
import store from '@/store'
import { mdiTicketPercentOutline, mdiTrendingUp } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const userdata = ref({ ...store.getters['auth/currentUser'] })
    const info = ref({})

    onMounted(async () => {
      const res = await CafeService.getStaffInfo()
      info.value = res.data.data
    })

    return {
      userdata,
      info,
      icons: {
        mdiTrendingUp,
        mdiTicketPercentOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: -7%;
  bottom: 0;
}
</style>
